/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components

import { Card, Autocomplete, CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Data
import { getResources } from "serviceHelper";

import AutocompleteSearch from "customComponents/AutocompleteSearch";

function Resource({ onSelected }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  // const onChangeHandle = async (value) => {
  //   // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
  //   console.log(value);

  //   const payload = { page: 1, limit: 20, search: value }; // static limit for now

  //   setLoading(true);

  //   const res = await getResources(payload);

  //   const { results } = res.data;

  //   setLoading(false);
  //   setOptions(results);
  // };

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={2}>
        <MDTypography variant="h6">Resource</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pt={0}>
        <AutocompleteSearch
          label={"Name"}
          apiUrl={"/v1/resources"}
          optionKey={"name"}
          onOptionSelected={(resp) => {
            onSelected && onSelected(resp);
          }}
        />
        {/* <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={options}
          loading={loading && open}
          fullWidth
          onInputChange={(event, value) => {
            if (event.type === "keydown" || event.type === "click") {
              console.log("onInputChange", value);
              const res =
                value && value !== "" ? options.find((option) => option.name === value) : null;
              onSelected && onSelected(res);
            }
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Name"
              variant="standard"
              onChange={(ev) => {
                // dont fire API if the user delete or not entered anything
                if (ev.target.value !== "" || ev.target.value !== null) {
                  onChangeHandle(ev.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && open ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        /> */}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props
Resource.defaultProps = {
  onSelected: null,
};

// Typechecking props for the DataTable
Resource.propTypes = {
  onSelected: PropTypes.func,
};

export default Resource;
