import axios from "axios";

const jwtClient = axios.create({});

jwtClient.interceptors.request.use((config) => {
  let tokens = JSON.parse(localStorage.getItem("tokens"));

  config.headers["Authorization"] = `bearer ${tokens.access.token}`;
  return config;
});

jwtClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        refreshToken: authData.refresh.token,
      };

      try {
        const apiResponse = await axios.post("/v1/auth/refresh-tokens", payload);

        const { data } = apiResponse;

        if (data) {
          localStorage.setItem("tokens", JSON.stringify(data));
          error.config.headers["Authorization"] = `bearer ${data.access.token}`;

          return axios(error.config);
        } else {
          return Promise.reject(error);
        }
      } catch (err) {
        console.log("refresh-tokens error:", err);

        localStorage.removeItem("tokens");

        return Promise.reject(err);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtClient;
