// import axios from "axios";
// import jwtInterceoptor from "jwtInterceptor";

// async function loginRequest(email, password) {
//   // return fetch("/v1/auth/login");
//   return axios.post("/v1/auth/login", { email, password });
// }

import jwtClient from "jwtClient";

async function getRequest(url, params) {
  return jwtClient.get(url, { params });
}

async function getUser(id) {
  const resp = await jwtClient.get(`/v1/users/${id}`);
  console.log("getUser resp", resp);
}

async function getResources(params) {
  return jwtClient.get("/v1/resources", { params });
}

async function addResource(payload) {
  return jwtClient.post("/v1/resources", payload);
}

async function editResource(resourceId, payload) {
  return jwtClient.patch(`/v1/resources/${resourceId}`, payload);
}

async function deleteResource(resourceId) {
  return jwtClient.delete(`/v1/resources/${resourceId}`);
}

async function syncProjects() {
  return jwtClient.post("/v1/projects/sync/all");
}

async function getProjects(params) {
  return jwtClient.get("/v1/projects", { params });
}

async function editProject(projectId, payload) {
  return jwtClient.patch(`/v1/projects/${projectId}`, payload);
}

async function addWorklog(payload) {
  return jwtClient.post("/v1/worklogs", payload);
}

async function editWorklog(worklogId, payload) {
  return jwtClient.patch(`/v1/worklogs/${worklogId}`, payload);
}

async function deleteWorklog(worklogId) {
  return jwtClient.delete(`/v1/worklogs/${worklogId}`);
}

async function getWorklogs(params) {
  return jwtClient.get("/v1/worklogs", { params });
}

export {
  getRequest,
  getUser,
  getResources,
  addResource,
  editResource,
  deleteResource,
  getProjects,
  editProject,
  addWorklog,
  editWorklog,
  deleteWorklog,
  getWorklogs,
  syncProjects,
};
