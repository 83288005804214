/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components

import MDAvatar from "components/MDBox";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";
import { useAlert } from "customComponents/Alert";

import AddDialog from "../AddDialog";

// Data
import { getWorklogs, addWorklog, editWorklog, deleteWorklog } from "serviceHelper";

import PropTypes from "prop-types";
import moment from "moment";

function ResourceWorklogs({ resource }) {
  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(false);
  const alert = useAlert();

  const [resourcesData, setResourcesData] = useState([]);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);

  const columns = [
    { Header: "task", accessor: "task", width: "30%", align: "left" },
    // { Header: "project", accessor: "project", align: "center" },
    { Header: "time", accessor: "time", align: "center" },
    { Header: "description", accessor: "description", align: "center" },
    { Header: "last Log", accessor: "lastLog", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  useEffect(() => {
    fetchData();
  }, [resource, page, limit]);

  const fetchData = useCallback(async () => {
    try {
      const { id } = resource;

      const payload = { resourceId: id, page, limit, sortBy: "createdAt:desc" };
      const res = await getWorklogs(payload);

      const { results, totalPages, totalResults } = res.data;

      const formateData = formatData(results);

      setResourcesData(formateData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);

      console.log(res);
    } catch (error) {
      console.log("error", error);
    }
  }, [resource, page, limit]);

  const addWorklogRequest = useCallback(
    async (payload, onSuccess) => {
      try {
        const res = await addWorklog(payload);
        console.log("Add Worklog", res);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Add Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [resource, page, limit]
  );

  const editWorklogRequest = useCallback(
    async (worklogId, payload, onSuccess) => {
      try {
        const res = await editWorklog(worklogId, payload);
        console.log("Edit Worklog", res);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Edit Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [resource, page, limit]
  );

  const deleteWorklogRequest = useCallback(
    async (worklogId, onSuccess) => {
      try {
        const res = await deleteWorklog(worklogId);
        console.log("Delete Worklog", res);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("Delete Worklog Error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [resource, page, limit]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      const { allProjects, projects, task, timeSeconds, description, lastLog } = value;

      const projectsMap = projects
        ? projects.map((project) => project.name + ": " + project.key)
        : [];

      const projectName = allProjects ? "All Projects" : projectsMap.join(", ");

      const lastLogFormated = lastLog
        ? moment(lastLog, "yyyy-MM-DDTHH:mm:ss.SSSZ").format("lll")
        : "n.a.";

      return {
        task: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            {/* <MDAvatar src={image} name={projectName} size="sm" variant="rounded" /> */}
            <MDBox display="flex" flexDirection="column" lineHeight={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {task}
              </MDTypography>

              <MDTypography variant="caption">{projectName}</MDTypography>
            </MDBox>
          </MDBox>
        ),
        time: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {(timeSeconds / 3600).toFixed(0)}h
          </MDTypography>
        ),
        description: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {description}
          </MDTypography>
        ),
        lastLog: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {lastLogFormated}
          </MDTypography>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify(value)}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (type = "add", worklogId = null, defaultValues = {}) => {
    setAddDialog({ type, worklogId, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const handleAddWorklog = () => {
    closeMenu();
    openAddDialog();
  };

  const handleEditWorklog = () => {
    closeMenu();

    const worklogId = menu.id;
    const dataJson = menu.getAttribute("data");

    const data = JSON.parse(dataJson);

    const { allProjects, projects, task, timeSeconds, description } = data;

    const projectNames = (projects ?? []).map((project) => project.name);

    openAddDialog("edit", worklogId, {
      allProjects,
      projects: projectNames,
      time: timeSeconds / 3600,
      task,
      description,
    });
  };

  const handleDeleteWorklog = () => {
    const worklogId = menu.id;

    closeMenu();

    alert.show(
      "Delete Worklog",
      `Are you sure you want to delete worklog?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          deleteWorklogRequest(worklogId);
        }
      }
    );
  };

  const handleSubmit = (data) => {
    const { id } = resource;
    const { type, worklogId, allProjects, projects, task, time, description } = data;

    const projectIds = projects.map((project) => project.id).join(",");

    console.log(`handleSubmit data`, JSON.stringify(data), projectIds);

    if (type === "add") {
      addWorklogRequest(
        {
          resourceId: id,
          allProjects,
          projectIds,
          task,
          timeSeconds: time * 3600,
          description,
        },
        closeAddDialog
      );
    } else {
      if (task || time || description) {
        editWorklogRequest(
          worklogId,
          { task, timeSeconds: time ? time * 3600 : undefined, description },
          closeAddDialog
        );
      } else {
        alert.show("Edit Worklog", "You must have at least one key to updete");
      }
    }
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleEditWorklog}>Edit</MenuItem>
      <MenuItem onClick={handleDeleteWorklog}>Delete</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
        <MDBox>
          <MDTypography variant="h6">Worklogs</MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            variant="h6"
            gutterBottom
            color="text"
            fontSize="small"
            onClick={handleAddWorklog}
          >
            ADD WORKLOG
          </MDTypography>
        </MDBox>
      </MDBox>

      {resourcesData && resourcesData.length > 0 ? (
        <MDBox>
          <APIDataTable
            table={{ rows: resourcesData, columns }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            currentPage={page}
            totalPages={totalPages}
            totalResults={totalResults}
            fetchData={(newPage, newLimit, newFilter) => {
              if (newPage !== page) {
                setPage(newPage);
              }

              if (newLimit !== limit) {
                setLimit(newLimit);
              }
            }}
          />
        </MDBox>
      ) : (
        <MDBox p={3}>
          <MDTypography variant="body2">No Worklog found</MDTypography>
        </MDBox>
      )}

      {renderMenu}
      {addDialog && (
        <AddDialog
          open={Boolean(addDialog)}
          {...addDialog}
          onClose={closeAddDialog}
          onSubmit={handleSubmit}
        />
      )}
    </Card>
  );
}

ResourceWorklogs.defaultProps = {
  resource: null,
};

// Typechecking props for the DataTable
ResourceWorklogs.propTypes = {
  resource: PropTypes.object,
};

export default ResourceWorklogs;
