import * as React from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { FormContainer, TextFieldElement, AutocompleteElement } from "react-hook-form-mui";

import PropTypes from "prop-types";

function AddDialog({ open, resourceId, type, defaultValues, onClose, onSubmit }) {
  const handleClose = () => {
    onClose && onClose();
  };

  const handleSubmit = (data) => {
    onSubmit && onSubmit({ ...data, type, resourceId });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
        <DialogTitle>{type === "add" ? "Add Resource" : "Edit Resource"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the required information</DialogContentText>
          <TextFieldElement
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            required
            disabled={type === "edit"}
          />
          <TextFieldElement
            margin="dense"
            name="token"
            label="Jira Token"
            type="text"
            fullWidth
            variant="standard"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  resourceId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(["add", "edit"]).isRequired,
  resourceId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
