/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import Footer from "customComponents/Footer";

// Dashboard components
import Resource from "./components/Resource";
import ResourceWorklogs from "./components/ResourceWorklogs";

function Worklogs() {
  const [resource, setResource] = useState(null);

  const handleSelection = (curResource) => {
    console.log("handleSelection: ", JSON.stringify(curResource));

    setResource(curResource);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Resource onSelected={handleSelection} />
          </Grid>

          {resource && (
            <Grid item xs={12} md={12} lg={12}>
              <ResourceWorklogs resource={resource} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Worklogs;
