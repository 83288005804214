/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useCallback, useMemo } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import APIDataTable from "customComponents/Tables/APIDataTable";

import { useAlert } from "customComponents/Alert";

import AddDialog from "../AddDialog";

// Data
import { getResources, addResource, editResource, deleteResource } from "serviceHelper";

function Resources() {
  const [menu, setMenu] = useState(null);
  const [addDialog, setAddDialog] = useState(null);

  const [resourcesData, setResourcesData] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState("");

  const alert = useAlert();

  const columns = useMemo(() => {
    return [
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      { Header: "token", accessor: "token", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", width: "10%", align: "center" },
    ];
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, filter]);

  const fetchData = useCallback(async () => {
    try {
      let payload = { page, limit, sortBy: "createdAt:desc" };

      if (filter && filter !== "") {
        payload["name"] = filter;
      }

      const res = await getResources(payload);

      const { results, totalPages, totalResults } = res.data;

      const formateData = formatData(results);

      setResourcesData(formateData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);

      console.log(res);
    } catch (error) {
      console.log("error", error);

      const title = error.response ? error.response.statusText : "Unknown error";
      const message =
        error.response && error.response.data ? error.response.data.message : error.message;

      alert.show(title, message);
    }
  }, [page, limit, filter]);

  const addResourceRequest = useCallback(
    async (body, onSuccess) => {
      try {
        const res = await addResource(body);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const editResourceRequest = useCallback(
    async (resourceId, updateBody, onSuccess) => {
      try {
        const res = await editResource(resourceId, updateBody);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const deleteResourceRequest = useCallback(
    async (resourceId) => {
      try {
        const res = await deleteResource(resourceId);

        console.log(res);

        fetchData();
      } catch (error) {
        console.log("error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const formatData = (res) => {
    return res.map((value, index) => {
      return {
        name: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            {value.avatarUrls && (
              <MDAvatar src={value.avatarUrls["48x48"]} name={value.name} size="sm" />
            )}
            <MDBox lineHeight={1} ml={1}>
              <MDTypography display="block" variant="button" fontWeight="medium">
                {value.name}
              </MDTypography>
              <MDTypography variant="caption">{value.email}</MDTypography>
            </MDBox>
          </MDBox>
        ),
        token: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {value.token}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            {value.active ? (
              <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
            ) : (
              <MDBadge badgeContent="inactive" color="dark" variant="gradient" size="sm" />
            )}
          </MDBox>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify({
              id: value.id,
              name: value.name,
              token: value.token,
              email: value.email,
              active: value.active,
            })}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openAddDialog = (type = "add", resourceId = null, defaultValues = {}) => {
    setAddDialog({ type, resourceId, defaultValues });
  };

  const closeAddDialog = () => {
    setAddDialog(null);
  };

  const handleAddResource = () => {
    closeMenu();
    openAddDialog();
  };

  const handleEditResource = () => {
    closeMenu();

    const resourceId = menu.id;
    const dataJson = menu.getAttribute("data");

    const data = JSON.parse(dataJson);

    openAddDialog("edit", resourceId, { email: data.email });
  };

  const handleDeleteResource = () => {
    const resourceId = menu.id;

    const dataJson = menu.getAttribute("data");

    const data = JSON.parse(dataJson);

    closeMenu();

    alert.show(
      "Delete Resource",
      `Are you sure you want to delete resource: ${data.name}?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          deleteResourceRequest(resourceId);
        }
      }
    );
  };

  const makeActiveOrInactive = () => {
    const resourceId = menu.id;
    const dataJson = menu.getAttribute("data");

    const data = JSON.parse(dataJson);

    closeMenu();

    alert.show(
      "Active/Inactive Resource",
      `Are you sure you want to make resource: ${data.name} ${
        data.active ? "in-active" : "active"
      }?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          editResourceRequest(resourceId, { active: !data.active });
        }
      }
    );
  };

  const handleSubmit = (data) => {
    console.log("Add/Edit Resource payload", JSON.stringify(data));

    const { type, email, token, resourceId } = data;

    if (type === "add") {
      addResourceRequest({ email, token }, closeAddDialog);
    } else {
      editResourceRequest(resourceId, { token }, closeAddDialog);
    }
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleEditResource}>Edit</MenuItem>
      <MenuItem onClick={makeActiveOrInactive}>Active / Inactive</MenuItem>
      <MenuItem onClick={handleDeleteResource}>Delete</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Resources
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDTypography
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            variant="h6"
            gutterBottom
            color="text"
            fontSize="small"
            onClick={handleAddResource}
          >
            ADD RESOURCE
          </MDTypography>
        </MDBox>
      </MDBox>
      {resourcesData && (
        <MDBox>
          <APIDataTable
            table={{ rows: resourcesData, columns }}
            showTotalEntries={true}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            canSearch
            currentPage={page}
            totalPages={totalPages}
            totalResults={totalResults}
            fetchData={(newPage, newLimit, newFilter) => {
              if (newPage !== page) {
                setPage(newPage);
              }

              if (newLimit !== limit) {
                setLimit(newLimit);
              }

              if (newFilter !== filter) {
                console.log("newFilter2", newFilter);
                setFilter(newFilter);
              }
            }}
          />
        </MDBox>
      )}

      {renderMenu}
      {addDialog && (
        <AddDialog
          open={Boolean(addDialog)}
          {...addDialog}
          onClose={closeAddDialog}
          onSubmit={handleSubmit}
        />
      )}
    </Card>
  );
}

export default Resources;
