/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import Footer from "customComponents/Footer";

// Dashboard components
import Resources from "./components/Resources";

import AuthContext from "authContext";
import { getUser } from "serviceHelper";

function ResourcesDashboard() {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      const { sub } = user;

      console.log(`userId: ${sub}`);

      // getUser(sub)
      //   .then((response) => {
      //     console.log("getUser: " + JSON.stringify(response));
      //   })
      //   .catch((err) => {
      //     console.log("getUser: " + err);
      //   });
    }
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Resources />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ResourcesDashboard;
