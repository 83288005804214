import React, { useEffect } from "react";

import {
  Button,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import MDBox from "components/MDBox";

import { Controller, useForm } from "react-hook-form";

import { FormContainer, TextFieldElement, CheckboxElement } from "react-hook-form-mui";
import AutocompleteSearch from "customComponents/AutocompleteSearch";

import PropTypes from "prop-types";

function AddDialog({ open, worklogId, type, defaultValues, onClose, onSubmit }) {
  const { control, handleSubmit, setValue, watch, reset } = useForm();

  const { projects, allProjects } = watch();

  useEffect(() => {
    Object.keys(defaultValues).forEach((key) => {
      console.log("defaultValues", key, defaultValues[key]);
      setValue(key, defaultValues[key]);
    });
  }, [defaultValues]);

  useEffect(() => {
    if (type === "add") {
      if (allProjects) {
        setValue("projects", []);
      } else {
        setValue("task", null);
      }
    }
  }, [allProjects]);

  useEffect(() => {
    if (type === "add") {
      setValue("task", null);
    }
  }, [projects]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleFormSubmit = (data) => {
    const { task, time, description, ...rest } = data;

    //const task = data.task !== defaultValues.task ? data.task : null;

    if (type === "add") {
      onSubmit && onSubmit({ ...data, type });
    } else {
      const { task, time, description, ...rest } = data;

      let updatedData = { ...rest };

      if (task && task !== defaultValues.task) {
        updatedData["task"] = task;
      }

      if (time && time !== defaultValues.time) {
        updatedData["time"] = time;
      }

      if (description && description !== defaultValues.description) {
        updatedData["description"] = description;
      }

      onSubmit && onSubmit({ ...updatedData, type, worklogId });
    }

    // reset();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormContainer control={control} onSuccess={handleSubmit(handleFormSubmit)}>
        <DialogTitle>{type === "add" ? "Add Resource" : "Edit Resource"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the required information.</DialogContentText>

          <MDBox display="flex" flexDirection="row-reverse">
            <CheckboxElement
              label="All Projects"
              control={control}
              name="allProjects"
              disabled={type === "edit"}
            />
          </MDBox>

          {type === "add" ? (
            <Controller
              control={control}
              name="projects"
              rules={{ required: !allProjects }}
              render={({ field: { onChange, value } }) => (
                <AutocompleteSearch
                  multiple
                  label={allProjects ? "All Projects" : "Projects"}
                  refreshKey={`project${Boolean(allProjects)}`}
                  value={value}
                  apiUrl={"/v1/projects"}
                  filter={{ status: true }}
                  optionKey={"name"}
                  disabled={type === "edit" || allProjects}
                  onOptionSelected={(resp) => {
                    console.log("onOptionSelected...", JSON.stringify(resp));
                    // if (resp) {
                    //   setValue("projectId", resp.id);
                    //   onChange(resp.name);
                    // } else {
                    //   setValue("projectId", null);
                    //   onChange(null);
                    // }
                    onChange(resp);
                  }}
                />
              )}
            />
          ) : (
            <TextFieldElement
              control={control}
              margin="dense"
              name="projects"
              label={allProjects ? "All Projects" : "Projects"}
              type="text"
              fullWidth
              variant="standard"
              disabled={true}
            />
          )}

          {/* </MDBox> */}

          <TextFieldElement
            control={control}
            margin="dense"
            name="task"
            label="Task"
            type="text"
            fullWidth
            variant="standard"
            required
            disabled={!((projects && projects.length > 0) || Boolean(allProjects))}
          />
          <TextFieldElement
            control={control}
            margin="dense"
            name="time"
            label="Time (in hours)"
            type="number"
            fullWidth
            variant="standard"
            required
          />
          <TextFieldElement
            control={control}
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </FormContainer>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
AddDialog.defaultProps = {
  open: false,
  worklogId: null,
  defaultValues: {},
  onClose: null,
  onSubmit: null,
};

// Typechecking props for the DataTable
AddDialog.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.oneOf(["add", "edit"]).isRequired,
  worklogId: PropTypes.string,
  defaultValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddDialog;
