/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, useCallback, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customComponents/Navbars/DashboardNavbar";
import Footer from "customComponents/Footer";
import APIDataTable from "customComponents/Tables/APIDataTable";

import { useAlert } from "customComponents/Alert";

import { getProjects, editProject } from "serviceHelper";

function Projects() {
  const [projectsData, setProjectsData] = useState(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [filter, setFilter] = useState("");

  const [showInactiveProjects, setShowInactiveProjects] = useState(false);

  const [menu, setMenu] = useState(null);
  const [projectMenu, setProjectMenu] = useState(null);

  const alert = useAlert();

  const columns = useMemo(() => {
    return [
      { Header: "project", accessor: "project", width: "30%", align: "left" },
      { Header: "key", accessor: "key", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ];
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, limit, filter, showInactiveProjects]);

  const fetchData = useCallback(async () => {
    try {
      let payload = { page, limit };

      if (filter && filter !== "") {
        payload["name"] = filter;
      }

      if (!showInactiveProjects) {
        payload["status"] = true;
      }

      const res = await getProjects(payload);

      const { results, totalPages, totalResults } = res.data;

      const formatedData = formatData(results);

      setProjectsData(formatedData);
      setTotalPages(totalPages);
      setTotalResults(totalResults);
    } catch (error) {
      console.log("error", error);
    }
  }, [page, limit, filter, showInactiveProjects]);

  const editProjectRequest = useCallback(
    async (projectId, updateBody, onSuccess) => {
      try {
        const res = await editProject(projectId, updateBody);

        fetchData();

        onSuccess && onSuccess();
      } catch (error) {
        console.log("error", error);

        const title = error.response ? error.response.statusText : "Unknown error";
        const message =
          error.response && error.response.data ? error.response.data.message : error.message;

        alert.show(title, message);
      }
    },
    [page, limit, filter]
  );

  const formatData = (dataList) => {
    return dataList.map((value, index) => {
      return {
        project: (
          <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDAvatar
              src={value.avatarUrls["48x48"]}
              name={value.name}
              size="sm"
              variant="rounded"
            />
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
            >
              {value.name}
            </MDTypography>
          </MDBox>
        ),
        key: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {value.key}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            {value.status ? (
              <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
            ) : (
              <MDBadge badgeContent="inactive" color="dark" variant="gradient" size="sm" />
            )}
          </MDBox>
        ),
        action: (
          <MDTypography
            component="a"
            color="text"
            id={value.id}
            data={JSON.stringify({
              id: value.id,
              name: value.name,
              status: value.status,
            })}
            onClick={openMenu}
          >
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      };
    });
  };

  const openMenu = ({ currentTarget }) => {
    setMenu(currentTarget);
  };

  const closeMenu = () => {
    setMenu(null);
  };

  const openProjectMenu = ({ currentTarget }) => {
    setProjectMenu(currentTarget);
  };

  const closeProjectMenu = () => {
    setProjectMenu(null);
  };

  const makeActiveOrInactive = () => {
    const projectId = menu.id;
    const dataJson = menu.getAttribute("data");

    const data = JSON.parse(dataJson);

    closeMenu();

    alert.show(
      "Active/Inactive Project",
      `Are you sure you want to make project: '${data.name}' ${
        data.status ? "in-active" : "active"
      }?`,
      ["No", "Yes"],
      (index) => {
        if (index === 1) {
          editProjectRequest(projectId, { status: !data.status });
        }
      }
    );
  };

  const showHideInActiveProjects = () => {
    closeProjectMenu();

    setPage(1);
    setShowInactiveProjects(!showInactiveProjects);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={makeActiveOrInactive}>Active / Inactive</MenuItem>
    </Menu>
  );

  const renderProjectMenu = (
    <Menu
      id="simple-menu"
      anchorEl={projectMenu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(projectMenu)}
      onClose={closeProjectMenu}
    >
      <MenuItem onClick={showHideInActiveProjects}>Show/ Hide in-active projects</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {projectsData && (
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Projects Table
              </MDTypography>

              <MDTypography variant="a" color="white" onClick={openProjectMenu}>
                <Icon>more_vert</Icon>
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <APIDataTable
                table={{ rows: projectsData, columns }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={true}
                noEndBorder
                canSearch
                currentPage={page}
                totalPages={totalPages}
                totalResults={totalResults}
                fetchData={(newPage, newLimit, newFilter) => {
                  if (newPage !== page) {
                    setPage(newPage);
                  }

                  if (newLimit !== limit) {
                    setLimit(newLimit);
                  }

                  if (newFilter !== filter) {
                    setFilter(newFilter);
                  }
                }}
              />
            </MDBox>
          </Card>
        )}
      </MDBox>
      {renderMenu}
      {renderProjectMenu}
      <Footer />
    </DashboardLayout>
  );
}

export default Projects;
