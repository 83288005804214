import * as React from "react";
import { createContext, useContext, useMemo, useRef, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import PropTypes from "prop-types";

const AlertContext = createContext();

function Alert({ open, title, message, actions, onAction }) {
  const handleAction = (id) => {
    onAction && onAction(id);
  };

  const handleClose = () => {
    onAction && onAction(0);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((value, index) => {
          return (
            <Button
              key={index}
              onClick={() => {
                handleAction(index);
              }}
            >
              {value}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}

// Setting default values for the props of DataTable
Alert.defaultProps = {
  open: false,
  title: "Title",
  message: "Message",
  actions: ["Ok"],
  onAction: null,
};

// Typechecking props for the DataTable
Alert.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,

  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string),
  onAction: PropTypes.func,
};

// export default Alert;

const AlertContextProvider = ({ children }) => {
  const alertContext = useRef(null);

  const [alertProp, setAlertProp] = useState(null);

  const show = (title, message, actions = ["Ok"], onAction = null) => {
    setAlertProp({ open: true, title, message, actions, action: onAction });
  };

  const hide = () => {
    setAlertProp(null);
  };

  const handleAction = (index) => {
    const { action } = alertProp;

    if (action) {
      action(index);
    }

    hide();
  };

  alertContext.current = { show, hide };

  return (
    <AlertContext.Provider value={alertContext}>
      {children}
      {alertProp && <Alert {...alertProp} onAction={handleAction} />}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const alertContext = useContext(AlertContext);
  const alert = useMemo(() => {
    return alertContext.current;
  }, [alertContext]);
  return alert;
};

export { useAlert, AlertContextProvider };
